<template>
  <b-modal id="new_card" size="lg" centered hide-header hide-footer :hide-backdrop="isEmbedded" :modal-class="isEmbedded ? 'embedded' : null">
    <h4 class="font-weight-400 mt-2 mb-3">Add new card</h4>
    <b-form>
      <vue-form-generator :model="payment" :schema="payment_schema" :options="formOptions" ref="payment_tab"></vue-form-generator>
      <b-form-group>
        Truzo is a safe and secure platform.
        <feather
          class="color-navy-blue align-bottom pt-2 ml-2 d-none d-md-inline-block"
          type="help-circle"
          v-b-popover.hover.top.html="'<p>All our systems are hosted by Amazon AWS and all information handled by us is encrypted using the latest technologies, protected by and processed in accordance with PCI DSS and ISO27001 standards. What does that mean? It means that your information cannot be traced, intercepted or used by unauthorised third parties. Even though it’s perfectly safe to store your credit card information with us, we will not do so without your consent!</p>'"
        ></feather>
        <feather
          class="color-navy-blue align-bottom pt-2 ml-2 d-inline-block d-md-none"
          type="help-circle"
          @click="$bvModal.show('truzo_is_safe')"
        ></feather>
        <!-- Modal -->
        <b-modal id="truzo_is_safe" centered hide-header hide-footer>
          <feather type="help-circle"></feather>
            <button type="button" aria-label="Close" class="close" @click="$bvModal.hide('truzo_is_safe')">×</button>
            <div class="pt-3 px-md-3 pb-md-3">
              <h5 class="color-navy-blue mb-4">Truzo is safe!</h5>
              <p>All our systems are hosted by <a href="https://aws.amazon.com/compliance/data-center/controls/" target="_blank">Amazon AWS</a> and all information handled by us is encrypted using the latest technologies, protected by and processed in accordance with <a href="https://www.pcisecuritystandards.org/pci_security/" target="_blank">PCI DSS</a> and <a href="https://www.iso.org/isoiec-27001-information-security.html" target="_blank">ISO27001 standards</a>. What does that mean? It means that your information cannot be traced, intercepted or used by unauthorised third parties. Even though it’s perfectly safe to store your credit card information with us, we will not do so without your consent!</p>
            </div>
        </b-modal>
        <!-- END | Modal -->
      </b-form-group>
      <b-form-group class="col-xl-12 px-md-0 mb-0">
        <b-row>
          <b-col lg="6" offset-lg="6" cols="12">
            <loading-button :replace="saving === true">
              <b-button variant="custom"  @click="save()" pill block class="mt-3">Add</b-button>
            </loading-button>
          </b-col>
        </b-row>
      </b-form-group>
    </b-form>
  </b-modal>
</template>

<script>
import { mapState, mapActions } from 'vuex'

const luhnValidator = function (value) {
  if (!value || !value.number) return ['Invalid card details.']

  const number = value.number
  var sum = 0
  for (var i = 0; i < number.length; i++) {
    var intVal = parseInt(number.substr(i, 1))
    if (i % 2 === 0) {
      intVal *= 2
      if (intVal > 9) {
        intVal = 1 + (intVal % 10)
      }
    }
    sum += intVal
  }
  if ((sum % 10) === 0) {
    return []
  } else {
    return ['Invalid card details.']
  }
}

const expiryDateValidator = function (value) {
  if (!value || !value.expiration) return ['Invalid expiry date.']
  if (!value.expMonth || !value.expYear) return ['Invalid expiry date.']

  const _month = value.expMonth
  const _year = value.expYear.length === 2 ? '20' + value.expYear : value.expYear
  const _today = new Date()
  const _someday = new Date()
  _someday.setFullYear(_year, _month, 1)

  if (_someday < _today) {
    return ['Invalid expiry date.']
  } else {
    return []
  }
}

const cvvValidator = function (value) {
  if (!value || !value.cvc) return ['Invalid CVV number.']

  const regex = /^[0-9]{3,4}$/
  if (!regex.test(value.cvc)) {
    return ['Invalid CVV number.']
  } else {
    return []
  }
}

const asyncCardValidator = async function (value) {
  const _luhn = luhnValidator(value)

  if (_luhn.length === 0) {
    const _expiry = expiryDateValidator(value)
    if (_expiry.length === 0) {
      return cvvValidator(value)
    } else {
      return _expiry
    }
  } else {
    return _luhn
  }
}

export default {
  components: {
  },
  data () {
    return {
      tableFields: [
        { key: 'currency', sortable: true, label: 'Currency', class: 'd-none d-lg-table-cell' },
        { key: 'brand', sortable: true, label: 'Brand', class: 'd-none d-lg-table-cell' },
        { key: 'card_name', sortable: true, label: 'Name' },
        { key: 'expiry_date', sortable: true, label: 'Expiry', class: 'text-right' },
        { key: 'card_number', sortable: true, label: 'Card Number', class: 'text-right' }
      ],
      saving: false,
      payment: {
        card: {
          number: null,
          expMonth: null,
          expYear: null,
          expiration: null,
          cvc: null
        },
        name: null
      },
      payment_schema: {
        fields: [
          {
            type: 'card-input-new',
            model: 'card',
            required: true,
            validator: [asyncCardValidator],
            styleClasses: 'col-12 col-xl-6'
          },
          {
            type: 'text-input',
            placeholder: 'Name of card holder',
            model: 'name',
            required: true,
            validator: ['required'],
            length: 3,
            styleClasses: 'col-12 col-xl-6 pl-xl-3 px-0'
          }
        ]
      },
      formOptions: {
        validationErrorClass: 'has-error',
        validationSuccessClass: 'has-success',
        validateAfterChanged: false,
        validateAsync: true
      },
      action: null,
      data: null
    }
  },
  computed: {
    ...mapState({
      alert: state => state.alert,
      user: state => state.user,
      cards: state => state.cards
    }),
    userLoaded () {
      return this.user.status
    },
    cardStatus () {
      return this.cards.status
    },
    isEmbedded () {
      if (this.$route.path.indexOf('embedded') >= 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('user', ['get']),
    ...mapActions('cards', {
      saveCard: 'save'
    }),
    save: async function () {
      this.saving = true
      const _response = await this.$refs.payment_tab.validate()
      console.log(_response)

      if (_response.length === 0) {
        const _card = this.payment.card
        _card.name = this.payment.name
        this.saveCard(_card)
      } else {
        this.saving = false
      }
    }
  },
  watch: {
    cardStatus: function (value) {
      if (value === 'saved') {
        this.payment = {
          card: {
            number: null,
            expMonth: null,
            expYear: null,
            expiration: null,
            cvc: null
          },
          name: null
        }
        this.get()
        this.saving = false
        this.$bvModal.hide('new_card')
      }
    }
  }
}
</script>

<style>
.cardinput-new {
  width: 100% !important;
}
</style>
