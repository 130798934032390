<template>
  <div id="payment-choose-cards" class="fee-method">
    <ul class="list-unstyled row mx-n1 mx-md-n3 transaction-select" v-match-heights="{el: ['div.equal-height']}">
      <li class="col-6 col-md-4 px-1 px-md-3 mb-3">
        <a href="#" @click.prevent="selected = 'preferred'" class="box" :class="selected === 'preferred' ? 'active' : ''">
          <div class="icon">
            <font-awesome-icon icon="stopwatch" size="3x" class="mt-3"></font-awesome-icon>
          </div>
          <div class="mt-1 equal-height">
            <p class="mb-2"><strong>{{ account.accountHolder.fullName }}</strong></p>
            <span v-if="bankName">{{ bankName }}<br /></span>
            {{ account.name }}<br />
            {{ account.accountNumber }}
          </div>
        </a>
      </li>
      <li class="col-6 col-md-4 px-1 px-md-3 mb-3">
        <a href="#" @click.prevent="selected = 'new'" class="box" :class="selected === 'new' ? 'active' : ''">
          <div class="icon">
            <font-awesome-icon icon="plus-circle" size="3x" class="mt-3"></font-awesome-icon>
          </div>
          <div class="mt-1 equal-height">
            <p class="mb-2"><strong>Use another account</strong></p>
            Once off, or save as a new preferred payment method.
          </div>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {
    account: Object
  },
  data () {
    return {
      selected: 'preferred'
    }
  },
  computed: {
    ...mapState({
      banks: state => state.banks.StitchBanks
    }),
    bankName () {
      const _bank = this.banks.find(_bank => _bank.name === this.account.bankId)
      if (_bank) {
        return _bank.truzo
      } else {
        return null
      }
    }
  },
  created () {
    this.$emit('card-selected', this.selected)
  },
  watch: {
    selected (_value) {
      this.$emit('card-selected', _value)
    }
  }
}
</script>
