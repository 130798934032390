<template>
  <b-modal id="3dsecure_existing" size="md" centered hide-header hide-footer :hide-backdrop="isEmbedded" :modal-class="isEmbedded ? 'embedded' : null">
    <h4 class="font-weight-400 color-grey mb-3">3D Secure Authentication</h4>
    <form id="secure_form_existing" ref="secure_form" :action="secure.url" method="POST" target="3dsecure_iframe_existing">
      <input type="hidden" name="PaReq" :value="secure.payload" />
      <input type="hidden" name="TermUrl" :value="returnUrl" />
      <input type="hidden" name="MD" :value="secure.md" />
    </form>
    <iframe style="border: 0px; width: 100%; height: 380px" name="3dsecure_iframe_existing">
    </iframe>
  </b-modal>
</template>

<script>
import { apiRoute } from '@/helpers/api-route'
import { mapActions, mapState } from 'vuex'

export default {
  data () {
    return {
      interval: undefined,
      returnUrl: undefined
    }
  },
  props: {
    card: String,
    transaction: Object,
    secure: Object
  },
  computed: {
    ...mapState({
      payments: state => state.payments
    }),
    paymentStatus () {
      return this.payments.status
    },
    isEmbedded () {
      if (this.$route.path.indexOf('embedded') >= 0) {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    ...mapActions('payments', {
      poll3dSecure: 'poll3dSecure'
    }),
    submitForm: function () {
      const self = this
      this.returnUrl = apiRoute() + '/api/v2/payment/3dsecureSaved/' + this.transaction._id + '/' + this.card

      this.$nextTick(() => {
        setTimeout(function () {
          const _form = document.getElementById('secure_form_existing')
          _form.submit()
          self.startPolling()
        }, 1000)
      })
    },
    startPolling: function () {
      const self = this
      this.interval = setInterval(() => {
        self.poll3dSecure({ id: self.transaction._id, interval: self.interval })
      }, 1000)
    }
  },
  watch: {
    paymentStatus (_status) {
      if (_status === 'failed') {
        clearInterval(this.interval)
        this.interval = null

        this.$bvModal.hide('3dsecure_existing')
      } else if (_status === 'paid') {
        clearInterval(this.interval)
        this.interval = null

        this.$bvModal.hide('3dsecure_existing')
      }
    }
  }
}
</script>
