<template>
  <div>
    <p class="mb-0 mb-md-5">
      Once you click "Complete payment", you will be redirected to an Instant EFT (<a href="https://ozow.com" target="_blank">Ozow</a> or <a href="https://stitch.money" target="_blank">Stitch</a>) payment platform. After your payment is processed successfully, you will be taken back to Truzo.
    </p>
    <choose-account v-if="preferred && preferred.details && preferred.details.account" :account="preferred.details.account" v-on:card-selected="selectAccount"></choose-account>
  </div>
</template>

<script>
import ChooseAccount from './ChooseAccount'
import { mapState } from 'vuex'

export default {
  components: {
    ChooseAccount
  },
  data () {
    return {
      selected: null
    }
  },
  computed: {
    ...mapState({
      record: state => state.transaction,
      transaction: state => state.transaction.transaction,
      company: state => state.company,
      status: state => state.auth,
      user: state => state.user
    }),
    loggedIn () {
      return this.status.status.loggedIn
    },
    loadStatus () {
      return this.record.status
    },
    preferred () {
      if (this.loggedIn && this.user.status === 'loaded') {
        let _preferred = null

        if (this.status.user.user.company) {
          _preferred = this.company && this.company.company ? this.company.company.preferred : null
        } else {
          _preferred = this.user && this.user.user ? this.user.user.preferred : null
        }

        if (_preferred && _preferred.type) {
          return {
            type: _preferred.type,
            details: _preferred.details
          }
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  methods: {
    selectAccount: function (value) {
      this.selected = value
    }
  },
  mounted () {
    this.$emit('update-can-pay', true)
  },
  watch: {
    selected (value) {
      if (value) {
        this.$emit('update-account', value)
        this.$emit('update-can-pay', true)
      } else {
        this.$emit('update-account', null)
        this.$emit('update-can-pay', false)
      }
    }
  }
}
</script>
