<template>
  <div id="payment-choose-bank" class="fee-method">
    <ul class="list-unstyled row mx-n1 mx-md-n3 transaction-select" v-if="banksStatus === 'loaded'" v-match-heights="{el: ['p.equal-height']}">
      <li class="col-6 col-md-4 px-1 px-md-3 mb-3 mb-md-0" v-for="(record, $index) in displayBanks" :key="$index">
        <a href="#" class="box">
          <img :src="require('@/assets/images/' + record.icon)" class="img-fluid d-block m-auto" v-if="record.icon" />
          <p class="mt-1 mb-2 equal-height"><strong>{{ record.name }}</strong></p>
          <span v-if="record.account">
            Account Number<br />
            <strong>{{ record.account }}</strong><br />
          </span>
          <span v-if="record.iban">
            IBAN<br />
            <strong>{{ record.iban }}</strong><br />
          </span>
          <span v-if="record.branch">
            Branch Code<br />
            <strong>{{ record.branch }}</strong><br />
          </span>
          <span v-if="record.sort_code">
            Sort Code<br />
            <strong>{{ record.sort_code }}</strong><br />
          </span>
          <span v-if="record.bic_code">
            BIC<br />
            <strong>{{ record.bic_code }}</strong><br />
          </span>
          <span v-if="record.intermediary_bic">
            Intermediary BIC<br />
            <strong>{{ record.intermediary_bic }}</strong><br />
          </span>
          <span v-if="record.swift">
            Swift Code<br />
            <strong>{{ record.swift }}</strong>
          </span>
          <span v-if="record.name === 'First National Bank'">
            Account Confirmation Letter<br />
            <strong><a href="https://truzo.com/wp-content/uploads/Truzo-FNB-Bank-Account-Confirmation-Letter.pdf" target="_blank">Download <feather type="download" size="14px"></feather></a></strong>
          </span>
          <span v-if="record.name === 'Absa'">
            Account Confirmation Letter<br />
            <strong><a href="https://truzo.com/wp-content/uploads/Truzo-ABSA-Bank-Confirmation-letter.pdf" target="_blank">Download <feather type="download" size="14px"></feather></a></strong>
          </span>
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  props: {
    currency: String
  },
  computed: {
    ...mapState({
      record: state => state.banks,
      banks: state => state.banks.banks
    }),
    banksStatus () {
      return this.record.status
    },
    displayBanks () {
      if (this.currency !== 'ZAR') {
        const _temp = []
        let _ccAdded = false
        this.banks.forEach(_bank => {
          if (_bank.name.indexOf('Currency Cloud') >= 0) {
            if (_bank.iban && !_ccAdded) {
              _ccAdded = true
              _temp.push(_bank)
            }
          } else {
            _temp.push(_bank)
          }
        })

        return _temp
      } else {
        return this.banks
      }
    }
  },
  mounted () {
    this.getWalletBanks(this.currency)
  },
  methods: {
    ...mapActions('banks', ['getWalletBanks'])
  }
}
</script>
